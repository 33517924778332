"use client";
import dynamic from "next/dynamic"


const MyAwesomeMap = dynamic(() => import("../components/LeafletMap"), { ssr:false })


export default function Home() {


  return (
         <div>
             <MyAwesomeMap />
         </div>

  );
}
